import React, { useRef, useState, useEffect } from 'react'

import { COURIERS } from '../../constants/couriers'
import { nextIcon, prevIcon, uploadFileIcon } from '../../constants/icons'
import { parseWorkbook, readFile } from '../../utils/files'
import { toJson } from '../../helpers/OrdersBilling'
import FormButton from '../shared/FormButton'
import Loader from '../shared/Loader'

const FileUploadModal = ({ saveData, isLoading }) => {
  const [courier, setCourier] = useState('chilexpress')
  const [currentStep, setCurrentStep] = useState(0)
  const [file, setFile] = useState(null)
  const [trackingColumn, setTrackingColumn] = useState('')
  const [weightColumn, setWeightColumn] = useState('')
  const [amountBilled, setAmountBilled] = useState('')
  const [clientRateColumn, setClientRateColumn] = useState('')
  const [lengthColumn, setLengthColumn] = useState('')
  const [widthColumn, setWidthColumn] = useState('')
  const [heightColumn, setHeightColumn] = useState('')
  const [volumeColumn, setVolumeColumn] = useState('')
  const [fileColumns, setFileColumns] = useState([])
  const [fileData, setFileData] = useState([])

  const inputRef = useRef(null)

  const setChilexpressDefaultData = () => {
    setTrackingColumn('documento_hijo')
    setLengthColumn('largo_pza')
    setHeightColumn('alto_pza')
    setWidthColumn('ancho_pza')
    setWeightColumn('peso_fisico_cxp')
    setVolumeColumn('peso_volumen_cxp')
    setAmountBilled('netovf')
  }

  const setBlueDefaultData = () => {
    setTrackingColumn('guia')
    setWeightColumn('kilos')
    setVolumeColumn('volumen')
    setAmountBilled('neto')
  }

  const setShippifyDefaultData = () => {
    setTrackingColumn('iddeentrega')
    setWeightColumn('pesototaldeartículos')
    setAmountBilled('preciodelacompañía')
  }

  const setUrbanoDefaultData = () => {
    setTrackingColumn('guiaelectronica')
    setWeightColumn('pesoespecifico')
    setVolumeColumn('pesovolumen')
    setAmountBilled('valorneto')
  }

  const setRecibeloDefaultData = () => {
    setTrackingColumn('idinterna')
    setAmountBilled('tags')
    setClientRateColumn('tarifacliente')
  }

  const setGlobalTrackingDefaultData = () => {
    setTrackingColumn('codigo')
    setClientRateColumn('costoglobal')
  }

  useEffect(() => {
    const dataSetter = {
      chilexpress: setChilexpressDefaultData,
      blueexpress: setBlueDefaultData,
      shippify: setShippifyDefaultData,
      urbano: setUrbanoDefaultData,
      recibelo: setRecibeloDefaultData,
      globaltracking: setGlobalTrackingDefaultData
    }

    if (courier && dataSetter[courier]) {
      dataSetter[courier]()
    }
  }, [courier])

  const courierSVG = (
    <div className="h-auto w-4">
      <svg
        fill={currentStep === 0 ? '#173554' : '#CCD9DE'}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth="0" />
        <g strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.729 9.647 21 9.129V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h2.351A3.46 3.46 0 0 0 6 22.5a3.5 3.5 0 0 0 7 0 3.46 3.46 0 0 0-.351-1.5h6.7A3.46 3.46 0 0 0 19 22.5a3.5 3.5 0 0 0 7 0 3.46 3.46 0 0 0-.351-1.5H29a1 1 0 0 0 .994-1.112l-.615-5.47a5.43 5.43 0 0 0-4.65-4.771m-.275 1.981A3.42 3.42 0 0 1 27.25 14H21v-2.852ZM5 8h14v11H5Zm6 14.5A1.5 1.5 0 1 1 9.5 21a1.5 1.5 0 0 1 1.5 1.5m13 0a1.5 1.5 0 1 1-1.5-1.5 1.5 1.5 0 0 1 1.5 1.5M21 19v-3h6.543l.337 3Z" />
      </svg>
    </div>
  )

  const uploadFileSVG = (
    <div className="h-auto w-4">
      <svg
        fill={currentStep === 1 ? '#173554' : '#CCD9DE'}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth="0" />
        <g strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.71 11.29a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-2 2a1 1 0 0 0 1.42 1.42l.29-.3V17a1 1 0 0 0 2 0v-2.59l.29.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42ZM20 8.94a1.3 1.3 0 0 0-.06-.27v-.09a1 1 0 0 0-.19-.28l-6-6a1 1 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1ZM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3Z" />
      </svg>
    </div>
  )

  const editColumnsSVG = (
    <div className="h-auto w-4">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="0" />
        <g strokeLinecap="round" strokeLinejoin="round" />
        <g
          stroke={currentStep === 2 ? '#173554' : '#CCD9DE'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m21.28 6.4-9.54 9.54c-.95.95-3.77 1.39-4.4.76s-.2-3.45.75-4.4l9.55-9.55a2.58 2.58 0 1 1 3.64 3.65" />
          <path d="M11 4H6a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h11c2.21 0 3-1.8 3-4v-5" />
        </g>
      </svg>
    </div>
  )

  const progressBarIcons = (step, svg) => (
    <div
      className={`flex h-7 w-7 items-center justify-center rounded-full border-2 
      ${currentStep === step - 1 ? 'border-pinflag-dark' : ''}`}
    >
      {svg}
    </div>
  )

  const progressBar = (steps) => (
    <div>
      <div className="mx-20 rounded-lg bg-gray-200">
        <div
          className="h-2 rounded-lg bg-pinflag-dark transition-all"
          style={{ width: `${currentStep * (100 / steps)}%` }}
        />
      </div>
      <div className="my-2 grid grid-cols-2">
        <div className="col-start-1 flex w-40 flex-col items-center justify-center text-sm">
          {progressBarIcons(1, courierSVG)}
          Seleccionar courier
        </div>
        <div className="col-start-2 flex w-40 flex-col items-center justify-center text-sm">
          {progressBarIcons(2, uploadFileSVG)}
          Subir Archivo
        </div>
        <div className="col-start-3 flex w-40 flex-col items-center justify-center text-sm">
          {progressBarIcons(3, editColumnsSVG)}
          Seleccionar columnas
        </div>
      </div>
    </div>
  )

  const handleFileUpload = async (e) => {
    setFile(e.target.files ? e.target.files[0] : null)
    const data = await readFile(e.target.files[0])
    const workbook = parseWorkbook(data)
    const parsedData = await toJson(courier, workbook)
    setFileData(parsedData)
    setFileColumns(Object.keys(parsedData[0]))
  }

  const handleSave = async () => {
    saveData(
      {
        tracking: trackingColumn,
        length: lengthColumn,
        height: heightColumn,
        width: widthColumn,
        weight: weightColumn,
        billed: amountBilled,
        volume: volumeColumn,
        rate: clientRateColumn
      },
      fileData,
      courier
    )
  }

  const columnSelector = (name, setter, options, defaultValue = '') => (
    <div className="my-4 flex items-center gap-2 border-[1px] p-3">
      <div className="w-28">{name}:</div>
      <select
        className="w-56 cursor-pointer rounded-sm border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        onChange={(e) => setter(e.target.value)}
        value={defaultValue}
        id={`${name}-select`}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )

  const renderChilexpressOptions = () => (
    <>
      {columnSelector('Volumen', setVolumeColumn, fileColumns, volumeColumn)}
      {columnSelector('Largo', setLengthColumn, fileColumns, lengthColumn)}
      {columnSelector('Alto', setHeightColumn, fileColumns, heightColumn)}
      {columnSelector('Ancho', setWidthColumn, fileColumns, widthColumn)}
    </>
  )

  const canPassSection = () => currentStep >= 2 || (currentStep === 1 && !file)

  return (
    <div className="flex overflow-hidden">
      <div className="mx-10 w-[50rem]">
        <div className="mb-4 w-full text-center text-xl">Subir Factura</div>
        {progressBar(2)}
        <div className="my-10 flex flex-col gap-5 overflow-hidden">
          {currentStep === 0 && (
            <div className="flex w-full flex-col items-center justify-center gap-4">
              {columnSelector('Courier', setCourier, COURIERS, courier)}
            </div>
          )}
          {currentStep === 1 && (
            <div className="flex w-full items-center justify-center gap-4 font-normal">
              <label
                htmlFor="billing-upload"
                className="cursor-pointer rounded-sm bg-pinflag-dark p-2 text-white hover:opacity-75"
              >
                <div className="flex items-center justify-center">
                  Subir Factura
                  {!file && (
                    <img
                      src={uploadFileIcon}
                      alt="Next"
                      className="h-6"
                      style={{
                        filter: 'brightness(0) invert(1)'
                      }}
                    />
                  )}
                </div>
                {file ? file.name : ''}
              </label>
              <input
                id="billing-upload"
                type="file"
                ref={inputRef}
                accept=".xls, .xlsx"
                onChange={(e) => handleFileUpload(e)}
                required
                style={{ display: 'none' }}
              />
            </div>
          )}
          {currentStep === 2 && (
            <div className="w-full">
              <div className="grid grid-cols-2 gap-2">
                {columnSelector('Tracking', setTrackingColumn, fileColumns, trackingColumn)}
                {courier !== 'recibelo'
                  ? columnSelector('Peso', setWeightColumn, fileColumns, weightColumn)
                  : columnSelector(
                      'Tarifa Cliente',
                      setClientRateColumn,
                      fileColumns,
                      clientRateColumn
                    )}
                {columnSelector('Cobrado', setAmountBilled, fileColumns, amountBilled)}
                {courier === 'chilexpress' && renderChilexpressOptions()}
                {(courier === 'blueexpress' || courier === 'urbano') &&
                  columnSelector('Volumen', setVolumeColumn, fileColumns, volumeColumn)}
              </div>
              <FormButton text="Guardar" styles="p-1 w-full" onClick={handleSave} />
              {isLoading && <Loader />}
            </div>
          )}
          <div className="flex justify-between">
            <button
              type="button"
              className={`p-2 ${
                currentStep < 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100'
              }`}
              onClick={() => setCurrentStep((prev) => prev - 1)}
              disabled={currentStep < 1}
              aria-label="Previous step"
            >
              {currentStep > 0 && <img src={prevIcon} alt="Previous" className="h-6" />}
            </button>

            <button
              type="button"
              className={`p-2 ${
                canPassSection() ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100'
              }`}
              onClick={() => setCurrentStep((prev) => prev + 1)}
              disabled={canPassSection()}
              aria-label="Next step"
            >
              {currentStep < 2 && <img src={nextIcon} alt="Next" className="h-6" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileUploadModal
