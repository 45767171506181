import React, { useState } from 'react'
import Table from '../../shared/Table/Table'
import ChatContainer from '../ManageTicket/ChatContainer'
import TicketRow from './TicketRow'
import useLocalStorage from '../../../hooks/useLocalStorage'

const TicketsTable = ({ tickets, handleSort, isLoading, error }) => {
  const [ticketId, setTicketId] = useState('')
  const [openChat, setOpenChat] = useState(false)
  const [openTicketId, setOpenTicketId] = useLocalStorage('openTicketId', null)

  const openChatHandler = (id) => {
    setTicketId(id)
    setOpenChat(true)
  }

  if (!isLoading && !error && tickets.length === 0) {
    return (
      <div className="mx-10 border-x border-b bg-white py-8 text-center text-s">
        No hay tickets con este criterio
      </div>
    )
  }

  const sortValues = {
    'O. de venta': { key: 'orderId', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    ID: { key: 'id', type: 'number' },
    Fecha: { key: 'createdAt', type: 'date' },
    Holding: { key: 'holdingName', type: 'string' },
    Motivo: { key: 'type', type: 'string' },
    Courier: { key: 'courier', type: 'string' },
    Actualización: { key: 'updatedAt', type: 'date' }
  }

  return (
    <>
      <Table
        maxHeight="max-h-[66vh]"
        sortValues={sortValues}
        sortData={handleSort}
        headers={[
          'O. de venta',
          'Estado',
          'ID',
          'Fecha',
          'Holding',
          'Motivo',
          'Courier',
          'Actualización',
          'Chat'
        ]}
        isLoading={isLoading}
        error={error}
      >
        {tickets.map((ticket) => (
          <TicketRow
            key={ticket.id}
            ticket={ticket}
            isSelected={openTicketId === ticket.id}
            onChatOpen={openChatHandler}
            setOpenTicketId={setOpenTicketId}
          />
        ))}
      </Table>
      {openChat && (
        <ChatContainer ticketId={ticketId} openChat={openChat} setOpenChat={setOpenChat} />
      )}
    </>
  )
}

export default TicketsTable
