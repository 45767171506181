import React from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import { linkIcon } from '../../../constants/icons'
import { COURIER_ROUTES } from '../../../constants/tickets'

const couriersWithTrackingId = new Set(['blueexpress', 'chilexpress', 'urbano', 'shippify', 'pickit', 'fedex'])

export const TrackingSearch = ({ courier, trackingId, customClass }) => {
  const route = COURIER_ROUTES[courier]
  let href = null

  if (route) {
    if (couriersWithTrackingId.has(courier)) {
      href = `${route}${trackingId}`
    } else {
      href = route
    }
  }

  return (
    <span className={`inline-flex items-center ${customClass}`}>
      {href ? (
        <a
          href={href}
          className="inline-flex underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {trackingId || '-'}
          <img src={linkIcon} alt="link" className={`ml-1 w-3 pt-1 ${customClass}`} />
        </a>
      ) : (
        <span>{trackingId || '-'}</span>
      )}
    </span>
  )
}



export const SaleSearch = ({ searchValue, customClass }) => (
  <Link
    to={`${ROUTES.SALE}?saleId=${searchValue}`}
    className={`inline-flex underline ${customClass}`}
  >
    {searchValue}
    <img src={linkIcon} alt="link" className={`ml-1 w-3 pt-1 ${customClass}`} />
  </Link>
)
