import React from 'react'

import { downloadDetail, getBills } from '../../../helpers/requests/billing'
import { formatDateWithDashesToSlashes } from '../../../utils/dates'
import { formatToCLP } from '../../../utils/currency'
import { BILL_STATUS, SUPPPORTED_COURIERS } from '../../../constants/billing'
import { BILLING_ICONS, SALES_DETAIL_BUTTONS_ICONS } from '../../../constants/icons'
import { MONTHS } from '../../../constants/others'
import ActionItem from '../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../shared/ActionsPopUp/ActionsPopUp'
import TableSection from '../TableSection'
import BillStatus from '../BillStatus'

const getBillingPeriod = (bill) => {
  const assignedMonth = MONTHS.filter((month) => month.id === bill.month)
  return `${assignedMonth[0].name}/${bill.year}`
}

const getBillingDetails = async (bill) => {
  const { data } = await downloadDetail(
    `?courierBillId=${bill.id}&name=${bill.awsFileName || ''}`
  )
  window.open(data.presignedUrl, '_blank')
}

const renderBillRow = (bill, setShouldFetch) => [
  <img src={BILLING_ICONS.fileInfo} className="h-auto w-5" alt='Ver detalles' />,
  <div className="flex w-28 items-center font-semibold">{bill['Courier.courier'] || '-'}</div>,
  <div className="w-28">
    <BillStatus status={bill.status} />
  </div>,
  <div className="flex w-28 items-center">{getBillingPeriod(bill) || '-'}</div>,
  <div className="flex w-28 items-center">{bill.invoiceNumber || '-'}</div>,
  <div className="flex w-28 items-center">{formatToCLP(bill.netValue) || '-'}</div>,
  <div className="flex w-28 items-center">{formatDateWithDashesToSlashes(bill.createdAt)}</div>,
  <div className="flex w-28 items-center">{formatDateWithDashesToSlashes(bill.paidAt)}</div>,
  <div className="flex w-28 items-center">{bill.amountOfPackages || '-'}</div>,
  <div>{`${(((bill.pinflagCharge - bill.netValue) / bill.pinflagCharge) * 100).toFixed(2)}%`}</div>,
  <div className="relative">
    <ActionsPopUp>
      <ActionItem
        icon={SALES_DETAIL_BUTTONS_ICONS.download}
        action={async () => {
          await getBillingDetails(bill)
          if(!bill.awsFileName) setShouldFetch(true)
        }}
        text="Descargar detalle"
      />
    </ActionsPopUp>
  </div>
]

const CourierBillsSection = ({ setShouldFetch, shouldFetch }) => (
  <TableSection
    title="Courier"
    fetchData={getBills}
    TableHeaders={[
      '',
      'Courier',
      'Estado',
      'Fecha',
      'N Factura',
      'Monto',
      'Fecha de emision',
      'Fecha de pago',
      'Cantidad',
      'Margen',
      ''
    ]}
    filters={BILL_STATUS.filter((status) => status.value <= 2 || status.value > 4)}
    filterOptions={{ couriers: SUPPPORTED_COURIERS }}
    renderRow={renderBillRow}
    type="couriers"
    shouldFetch={shouldFetch}
    setShouldFetch={setShouldFetch}
  />
)
export default CourierBillsSection
