import React, { useState } from 'react'
import Chat from './Chat'
import useFetch from '../../../hooks/useFetch'
import { getTicket } from '../../../helpers/requests/tickets'
import Modal from '../../shared/Modal/Modal'

const ChatContainer = ({ ticketId, openChat, setOpenChat }) => {
  const [ticket, setTicket] = useState({})
  const { isLoading, error } = useFetch(getTicket, setTicket, ticketId)
  return (
    !isLoading &&
    !error && (
      <Modal title="Chat" show={openChat} handleClose={() => setOpenChat(false)}>
        <Chat
          chatHistory={ticket.TicketChats}
          ticketId={ticket.id}
          context={ticket}
        />
      </Modal>
    )
  )
}
export default ChatContainer
