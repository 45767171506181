import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from './packageStatus'

export const ASSETS_URL = 'https://company-administrator-assets.s3.sa-east-1.amazonaws.com'

export const SHOW_EYE = {
  show: `${ASSETS_URL}/general/show-eye.svg`,
  hide: `${ASSETS_URL}/general/hide-eye.svg`
}

export const TICKET_ICONS = {
  chat: `${ASSETS_URL}/tickets/chat.svg`,
  switch: `${ASSETS_URL}/tickets/switch.svg`,
  files: `${ASSETS_URL}/tickets/files.svg`,
  send: `${ASSETS_URL}/tickets/send.svg`
}

export const PAGINATION_ICONS = {
  arrowRight: `${ASSETS_URL}/pagination/arrow-right.png`,
  arrowLeft: `${ASSETS_URL}/pagination/arrow-left.png`
}

export const FILE_EXTENSION_ICONS = {
  pdf: `${ASSETS_URL}/files/pdf.svg`,
  xlsx: `${ASSETS_URL}/files/xlsx.svg`,
  zip: `${ASSETS_URL}/files/zip.svg`,
  generic: `${ASSETS_URL}/files/generic.svg`
}

export const STATS_ICONS = {
  time: `${ASSETS_URL}/dashboard-icons/time.png`
}

export const BILLING_ICONS = {
  excelActive: `${ASSETS_URL}/billing-icons/icon-excel-active.svg`,
  excelInactive: `${ASSETS_URL}/billing-icons/icon-excel-inactive.svg`,
  notPaid: `${ASSETS_URL}/billing-icons/icon-notpaid.svg`,
  paid: `${ASSETS_URL}/billing-icons/icon-paid.svg`,
  pdfActive: `${ASSETS_URL}/billing-icons/icon-excel-active.svg`,
  pdfInactive: `${ASSETS_URL}/billing-icons/icon-excel-inactive.svg`,
  fileInfo: `${ASSETS_URL}/billing-icons/icon-file-info.svg`,
  article: `${ASSETS_URL}/billing-icons/article.svg`
}

export const SALES_ICONS = {
  printIcon: `${ASSETS_URL}/sales/print-icon.svg`,
  pictureIcon: `${ASSETS_URL}/sales/picture-icon.svg`,
  editLocationIcon: `${ASSETS_URL}/sales/edit-location-icon.svg`,
  personalInfo: `${ASSETS_URL}/sales/personal-info.png`,
  shippingInfo: `${ASSETS_URL}/sales/shipping-info.png`,
  printWhiteIcon: `${ASSETS_URL}/sales/print-icon-white.png`,
  createOrder: `${ASSETS_URL}/sales/create-order.png`,
  simpleOrder: `${ASSETS_URL}/sales/simple-order.png`,
  masiveOrder: `${ASSETS_URL}/sales/masive-order.png`
}

export const SALES_DETAIL_ICONS = {
  package: `${ASSETS_URL}/sales/detail/package.png`,
  person: `${ASSETS_URL}/sales/detail/person.png`,
  purchase: `${ASSETS_URL}/sales/detail/purchase.png`,
  shipping: `${ASSETS_URL}/sales/detail/shipping.png`,
  timeline: `${ASSETS_URL}/sales/detail/timeline.png`,
  payment: `${ASSETS_URL}/sales/detail/payment.png`,
  detail: `${ASSETS_URL}/sales/detail/detail.png`
}

export const SALES_DETAIL_BUTTONS_ICONS = {
  ticket: `${ASSETS_URL}/sales/detail-buttons/ticket-white.png`,
  evidencePhoto: `${ASSETS_URL}/sales/detail-buttons/evidence-photo.png`,
  edit: `${ASSETS_URL}/sales/detail-buttons/edit.png`,
  download: `${ASSETS_URL}/sales/detail-buttons/download.png`
}

export const PACKAGE_STATUS_ICONS = {
  [PACKAGE_BOUGHT]: `${ASSETS_URL}/sales/status/successful.png`,
  [PACKAGE_PROCESSED]: `${ASSETS_URL}/sales/status/processed.png`,
  [PACKAGE_IN_TRANSIT]: `${ASSETS_URL}/sales/status/in-transit.png`,
  [PACKAGE_ON_DELIVERY]: `${ASSETS_URL}/sales/status/on-delivery.png`,
  [PACKAGE_RECEIVED]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_STORED]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_PICKED_UP]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_DELIVERED]: `${ASSETS_URL}/sales/status/delivered.png`
}

export const backArrow = `${ASSETS_URL}/general/back-arrow.png`

export const downArrow = `${ASSETS_URL}/general/down-arrow.svg`

export const infoIcon = `${ASSETS_URL}/general/info.svg`

export const descriptionImage = `${ASSETS_URL}/general/descripcion-formula.png`

export const nextIcon = `${ASSETS_URL}/general/icon-next.svg`

export const prevIcon = `${ASSETS_URL}/general/icon-previous.svg`

export const viewDetails = `${ASSETS_URL}/general/view-details.png`

export const blackChevron = `${ASSETS_URL}/general/black-chevron.png`

export const chevron = `${ASSETS_URL}/general/down-arrow.svg`

export const redirectIcon = `${ASSETS_URL}/general/redirect.png`

export const searchIcon = `${ASSETS_URL}/general/search-icon.svg`

export const pinflagWhiteIcon = `${ASSETS_URL}/general/pinflag-white.png`

export const pinflagWhiteHorizontalIcon = `${ASSETS_URL}/general/pinflag-white-horizontal.png`

export const downloadIcon = `${ASSETS_URL}/general/download.svg`

export const chevron2 = `${ASSETS_URL}/general/chevron.png`

export const greenCheck = `${ASSETS_URL}/general/green-check.svg`

export const uploadIcon = `${ASSETS_URL}/general/upload.png`

export const errorIcon = `${ASSETS_URL}/general/icon-error.svg`

export const editIcon2 = `${ASSETS_URL}/general/edit-icon-2.svg`

export const linkIcon = `${ASSETS_URL}/general/link-icon.svg`

export const uploadFileIcon = `${ASSETS_URL}/general/uploadFile.png`

export const bellIcon = `${ASSETS_URL}/general/bell-icon.svg`

export const filterIcon = `${ASSETS_URL}/general/filter.png`

export const exportIcon = `${ASSETS_URL}/general/export.png`
