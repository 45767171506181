import React, { useState } from 'react'

import { sendTicketMessage } from '../../../helpers/requests/tickets'
import { formatDate } from '../../../utils/dates'
import { TICKET_ICONS } from '../../../constants/icons'
import ChatDetail from './ChatDetail'

const Chat = ({ chatHistory, ticketId, context }) => {
  const [history, setHistory] = useState(chatHistory)
  const [messageTosend, setMessageToSend] = useState('')
  const [showDetails, setShowDetails] = useState(false)

  const sendMessage = async () =>  {
    const responseData = await sendTicketMessage(ticketId, messageTosend)
    setHistory(history.concat(responseData))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    sendMessage()
    setMessageToSend('')
  }

  return (
    <div className="mt-4 flex max-h-[75vh] min-h-[30vh] w-[35rem] max-w-full flex-col justify-between gap-2 rounded-lg bg-light-grey p-2">
      {context && (
        <div className="mt-2 flex flex-col justify-between rounded-lg bg-pinflag p-1">
          <button
            type="button"
            onClick={() => setShowDetails((prevState) => !prevState)}
            className="w-full rounded-t-lg border-b-2 border-white bg-pinflag py-2 px-3 text-left font-semibold
        text-white focus:outline-none"
          >
            {showDetails ? 'Ocultar Detalles' : 'Mostrar Detalles'}
          </button>

          {showDetails && context && <ChatDetail ticket={context} showDetails={showDetails} />}
        </div>
      )}

      <div className="overflow-y-auto">
        {history.length === 0 && (
          <div className="my-4 text-center text-medium-gray">Aun no hay mensajes</div>
        )}
        {history.map((message) => (
          <div
            key={message.createdAt}
            className={`${
              message.sender === 'ecommerce' ? 'mr-auto ml-2' : 'ml-auto mr-2 text-end'
            } mt-2 w-fit max-w-[65%]`}
          >
            <div
              className={`w-fit overflow-hidden break-words rounded-b-2xl py-2
              px-4 text-sm ${
                message.sender === 'ecommerce'
                  ? 'ml-0 mr-auto rounded-tr-2xl bg-white'
                  : 'mr-0 ml-auto rounded-tl-2xl bg-pinflag text-white'
              }`}
            >
              {message.message}
            </div>
            <div className="text-xs text-dark-grey">{formatDate(message.createdAt)}</div>
          </div>
        ))}
      </div>
      <form className="relative m-2" onSubmit={handleSubmit}>
        <textarea
          className="h-fit w-full resize-y break-words rounded-2xl p-3 pr-6 text-sm shadow-lg"
          placeholder="Escribe un mensaje"
          value={messageTosend}
          onChange={(event) => setMessageToSend(event.target.value)}
          maxLength="500"
        />
        <button type="submit" className="absolute bottom-4 right-2">
          <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
        </button>
      </form>
    </div>
  )
}

export default Chat
