import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import useFetch from '../../../hooks/useFetch'
import { getHoldings } from '../../../helpers/requests/companies'
import { createBills, downloadDetail, getBills } from '../../../helpers/requests/billing'
import { formatDateWithDashesToSlashes } from '../../../utils/dates'
import { formatToCLP } from '../../../utils/currency'
import { BILL_STATUS } from '../../../constants/billing'
import ROUTES from '../../../constants/routes'
import { BILLING_ICONS, SALES_DETAIL_BUTTONS_ICONS } from '../../../constants/icons'
import ActionsPopUp from '../../shared/ActionsPopUp/ActionsPopUp'
import ActionItem from '../../shared/ActionsPopUp/ActionItem'
import BillStatus from '../BillStatus'
import TableSection from '../TableSection'

import CreateBillModal from './CreateBillModal'

const HoldingBillsSection = ({ setShouldFetch, shouldFetch }) => {
  const [showModal, setShowModal] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({})
  const { push } = useHistory()

  const initializeData = useCallback((holdings) => {
    setFilterOptions({ clientes: holdings.map((holding) => holding.name) })
  }, [])

  useFetch(getHoldings, initializeData)

  const getBillingDetails = async (bill) => {
    const { data } = await downloadDetail(
      `?billId=${bill.id}&name=${bill.awsFileName || ''}`
    )
    window.open(data.presignedUrl, '_blank')
  }

  const handleBillCreation = async () => {
    setIsLoading(true)
    const response = await createBills('')
    setResponseMessage(response)
    setIsLoading(false)
  }

  const renderBillRow = (bill) => [
    <button
      type="button"
      className="flex w-5 items-center"
      onClick={() => push(`${ROUTES.BILLINGS}/${bill.id}`)}
    >
      <img src={BILLING_ICONS.fileInfo} className="h-auto w-5" alt='Ver detalles' />,
    </button>,
    <div className="flex w-28 items-center font-semibold">{bill['Holding.name'] || '-'}</div>,
    <div className="w-28">
      <BillStatus status={bill.status} />
    </div>,
    <div className="flex w-28 items-center">{bill.invoiceNumber || '-'}</div>,
    <div className="flex w-28 items-center">{formatToCLP(bill.pinflagCharge) || '-'}</div>,
    <div className="flex w-28 items-center">{formatDateWithDashesToSlashes(bill.createdAt)}</div>,
    <div className="flex w-28 items-center">{formatDateWithDashesToSlashes(bill.paidAt)}</div>,
    <div className="flex w-28 items-center">{bill.amountOfPackages || '-'}</div>,
    <div>{`${(((bill.pinflagCharge - bill.netValue) / bill.pinflagCharge) * 100).toFixed(
      2
    )}%`}</div>,
    <div className="relative">
      <ActionsPopUp>
        <ActionItem
          icon={SALES_DETAIL_BUTTONS_ICONS.edit}
          action={() => push(`facturas/${bill.holdingId}/${bill.id}`)}
          text="Editar factura"
        />
        <ActionItem
          icon={SALES_DETAIL_BUTTONS_ICONS.download}
          action={async() => {
          await getBillingDetails(bill)
          if(!bill.awsFileName) setShouldFetch(true)
          }}
          text="Descargar detalle"
        />
      </ActionsPopUp>
    </div>
  ]

  return (
    <>
      <CreateBillModal
        isLoading={isLoading}
        responseMessage={responseMessage}
        setResponseMessage={setResponseMessage}
        handleBillCreation={handleBillCreation}
        setShouldFetch={setShouldFetch}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <TableSection
        title="Clientes"
        fetchData={getBills}
        TableHeaders={[
          '',
          'Cliente',
          'Estado',
          'N de factura',
          'Monto',
          'Fecha de emision',
          'Fecha de pago',
          'Cantidad',
          'Margen',
          ''
        ]}
        filters={BILL_STATUS}
        filterOptions={filterOptions}
        renderRow={renderBillRow}
        setShowModal={setShowModal}
        shouldFetch={shouldFetch}
        setShouldFetch={setShouldFetch}
      />
    </>
  )
}
export default HoldingBillsSection
