import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import BreadCrumbHeader from '../../components/shared/BreadCrumbHeader'
import ROUTES from '../../constants/routes'
import Table from '../../components/shared/Table/Table'
import TableRow from '../../components/shared/Table/TableRow'
import HoldingSummary from '../../components/Billing/BillingDetail/HoldingSummary'
import ShippingSummary from '../../components/Billing/BillingDetail/ShippingSummary'
import TotalSummary from '../../components/Billing/BillingDetail/TotalSummary'
import { downloadDetail, getBilledPackages, getBills } from '../../helpers/requests/billing'
import useFetch from '../../hooks/useFetch'
import { downloadIcon, editIcon2 } from '../../constants/icons'
import Loader from '../../components/shared/Loader'
import { getHolding } from '../../helpers/requests/notificationCenter'

const BillingDetail = () => {
  const [packages, setPackages] = useState({ foundPackages: [] })
  const [billing, setBilling] = useState({})
  const [holding, setHolding] = useState({})

  const { id } = useParams()
  const { push } = useHistory()

  const initializeBilling = useCallback((bill) => {
    setBilling(bill.bills[0])
  }, [])

  const { isBillLoading, billError } = useFetch(getBills, initializeBilling, `?billId=${id}`)
  const { isPackagesLoading, packagesError } = useFetch(
    getBilledPackages,
    setPackages,
    `?billingId=${id}`
  )

  useEffect(() => {
    const getHoldingData = async () => {
      if (billing.holdingId) {
        const result = await getHolding(`?holdingId=${billing.holdingId}`)
        setHolding(result)
      }
    }
    getHoldingData()
  }, [billing])

  const getBillingDetails = async () => {
    const { data } = await downloadDetail(`?billId=${billing.id}&name=${billing.awsFileName || ''}`)
    window.open(data.presignedUrl, '_blank')
  }

  const getRowValue = (item, key) => {
    if(key === 'paid') return item[key] ? 'pagado' : 'pendiente'

    return item[key]
  }

  const headers = {
    companyName: 'Empresa',
    packageId: 'Package_id',
    orderId: 'OV',
    trackingNumber: 'OT',
    courier: 'Courier',
    arrivalDate: 'Fecha Entrega',
    cityName: 'Comuna',
    courierService: 'Tipo envío',
    paid: 'Estado',
    shippingPaid: 'Pagado por cliente',
    billed: 'Precio OV',
    weightBilled: 'Peso',
    courierPrice: 'Transporte'
  }

  if (isBillLoading || billError || isPackagesLoading || packagesError) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isBillLoading || isPackagesLoading ? (
            <Loader />
          ) : (
            <div>Ha ocurrido un error. Inténtelo más tarde.</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey p-10">
      <div className="flex justify-between">
        <BreadCrumbHeader
          goBack={() => push(ROUTES.BILLINGS)}
          title="Facturas"
          subtitle="Detalle"
        />
        <div className="mb-8 flex">
          <button
            className="mr-2 flex items-center justify-center rounded-lg border-2 bg-pinflag p-2 text-sm text-white"
            onClick={() => push(`${billing.holdingId}/${billing.id}`)}
            type="button"
          >
            <img src={editIcon2} alt="exportar" className="my-auto mx-1.5 w-4" />
            Editar
          </button>
          <button
            className="flex items-center justify-center rounded-lg border-2 bg-pinflag p-2 text-sm text-white"
            onClick={async () => getBillingDetails()}
            type="button"
          >
            <img src={downloadIcon} alt="exportar" className="my-auto mx-1.5 w-4" />
            Descargar
          </button>
        </div>
      </div>

      <div className="rounded-3xl bg-white pb-10">
        <div className="m-10 flex h-48 justify-between">
          <HoldingSummary holding={holding} />
          <ShippingSummary bill={billing} />
        </div>

        <div className="ml-10 mb-5">Items septiembre</div>
        <Table headers={Object.values(headers)} maxHeight="max-h-[66vh]" roundedTop>
          {packages.foundPackages.map((billPackage) => (
            <TableRow
              key={billPackage.id}
              items={Object.keys(headers).map((billKey) => (
                <div className="flex items-start">{getRowValue(billPackage,billKey)}</div>
              ))}
            />
          ))}
        </Table>
      </div>
      <TotalSummary
        totals={{
          subtotal: billing.netValue,
          discounts: `ClickAndCollect: ${holding.clickAndCollectDiscount}`,
          extras: packages.foundPackages.reduce((total, pkg) => total + pkg.extras, 0),
          total: billing.pinflagCharge
        }}
      />
    </div>
  )
}

export default BillingDetail
