/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import { getFormattedDate } from '../../../utils/dates'
import { STATUS_COLOR, STATUS_ICONS } from '../../../constants/tickets'
import Button from '../../shared/Button'
import { TICKET_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'

const TicketRow = ({ ticket, isSelected, onChatOpen, setOpenTicketId }) => {
  const ticketColor = isSelected ? 'bg-blue-100/40' : ''

  const items = [
    ticket.orderId,
    <div className={`flex w-max gap-2 rounded py-1 px-2 ${STATUS_COLOR[ticket.status]}`}>
      <img
        src={STATUS_ICONS[ticket.status]}
        alt={ticket.status}
        className="my-auto h-auto w-2.5"
      />
      {ticket.status}
    </div>,
    ticket.id,
    getFormattedDate(ticket.createdAt),
    ticket.holdingName,
    ticket.type.split('/')[1].trim(),
    ticket.courier || '-',
    getFormattedDate(ticket.updatedAt),
    <Button
      color="bg-pinflag"
      small
      onClick={() => onChatOpen(ticket.id)}
    >
      <img src={TICKET_ICONS.chat} alt="chat" className="h-6 py-1" />
    </Button>
  ]

  return (
    <tr className='w-full'>
      <td className={`w-8 ${ticketColor}`} />
      {items.map((item, index) => (
        <td key={index} className={`whitespace-nowrap p-1 text-left ${ticketColor}`}>
          {index !== 8 ? (
            <Link
              to={`${ROUTES.TICKETS}/${ticket.id}`}
              className="block p-0"
              onClick={() => setOpenTicketId(ticket.id)}
            >
              {item || '-'}
            </Link>
          ) : (
            item || '-'
          )}
        </td>
      ))}
    </tr>
  )
}

export default TicketRow
