import React, { useCallback, useEffect, useState } from 'react'

import { formatToCLP } from '../../../utils/currency'

import TableSection from '../TableSection'
import {
  getBilledPackages,
  getMissingPackages,
  updateMissingPackages,
  updatePackages
} from '../../../helpers/requests/billing'
import useFetch from '../../../hooks/useFetch'
import { getHoldings } from '../../../helpers/requests/companies'
import { chevron } from '../../../constants/icons'
import { getStates } from '../../../helpers/requests/states'
import EditParcel from './EditParcel'
import { PARCEL_FILTER_OPTIONS, PARCEL_TYPE, SUPPPORTED_COURIERS } from '../../../constants/billing'

const statusBar = (bill) => {
  let bgColor = ''
  if (bill.review === 'pending') bgColor = 'bg-red'
  if (bill.review === 'modified') bgColor = 'bg-[#1C79C0]'
  if (bill.review === 'no') bgColor = 'bg-green'
  return <div className={`absolute left-[2.5rem] z-0 h-16 w-[0.3rem] rounded-r-xl ${bgColor}`} />
}

const ParcelsBillSection = ({
  editedPackages,
  setEditedPackages,
  setUpdatePackagesFunction,
  setShouldFetch,
  shouldFetch
}) => {
  const [filterOptions, setFilterOptions] = useState([])
  const [holdingsOptions, setHoldingsOptions] = useState([])
  const [packagesType, setPackagesType] = useState(PARCEL_TYPE[0])
  const [parcelOptions, setParcelOptions] = useState(false)
  const [currentParcel, setCurrentParcel] = useState({})
  const [cities, setCities] = useState([])

  const renderBillRow = (bill) => [
    <>
      <button
        type="button"
        className="flex h-12 w-full items-center"
        onClick={() => {
          setParcelOptions((prev) => !prev)
          setCurrentParcel({ ...bill })
        }}
      >
        <img
          src={chevron}
          className={`h-auto w-3 ${
            parcelOptions && currentParcel?.id === bill.id ? 'rotate-180' : ''
          }`}
          alt="Edit details"
        />
        {statusBar(bill)}
      </button>
      {parcelOptions && currentParcel?.id === bill.id && (
        <EditParcel
          billData={bill}
          setCurrentParcel={setCurrentParcel}
          setEditedPackages={setEditedPackages}
          editedPackages={editedPackages}
          currentParcel={currentParcel}
          setOpenOptions={setParcelOptions}
          cities={cities}
        />
      )}
    </>,
    <div className="flex h-12 w-full items-center font-semibold"> {bill.companyName}</div>,
    <div className="flex h-12 w-full items-center"> {bill.courier}</div>,
    <div className="flex h-12 w-full items-center"> {bill.cityName}</div>,
    <div
      className={`flex h-fit w-fit items-center ${
        bill.declaredWeight > 100 ? 'rounded-full bg-[#F0AA55]/60 px-1' : ''
      }`}
    >
      {bill.declaredWeight}
    </div>,
    <div className="flex h-12 w-full items-center"> {bill.weightBilled}</div>,
    <div className="flex h-12 w-full items-center">
      <div
        className={`flex w-fit items-center ${
          bill.billed > 10000 ? 'rounded-full bg-[#F0AA55]/60 px-1' : ''
        }`}
      >
        {formatToCLP(bill.billed)}
      </div>
    </div>,
    <div className="flex h-12 w-full items-center"> {formatToCLP(bill.basePrice)}</div>,
    <div className="flex h-12 w-full items-center"> {formatToCLP(bill.courierPrice)}</div>
  ]

  const initializeHoldings = useCallback((holdings) => {
    setHoldingsOptions({ clientes: holdings.map((holding) => holding.name) })
  }, [])

  const initializeCities = useCallback((states) => {
    setCities(
      Object.values(states).flatMap((state) =>
        state.cities.map((city) => ({
          id: city.id,
          name: city.name
        }))
      )
    )
  }, [])

  useFetch(getHoldings, initializeHoldings)
  useFetch(getStates, initializeCities)

  useEffect(() => {
    const options = { ...holdingsOptions,  couriers: SUPPPORTED_COURIERS }
    if (PARCEL_FILTER_OPTIONS[packagesType].length > 0) {
      options.estado = PARCEL_FILTER_OPTIONS[packagesType]
    }
    setFilterOptions(options)
  }, [holdingsOptions, packagesType])

  const packagesTypeSelector = () => (
    <div className="-mb-4">
      <button
        type="button"
        onClick={() => setPackagesType(PARCEL_TYPE[0])}
        className={`${
          packagesType === PARCEL_TYPE[0] ? 'bg-white' : 'bg-semi-light-grey'
        } h-10 rounded-t-md  border-[1px] border-t-2 border-semi-light-grey px-2 font-semibold`}
      >
        Pedidos
      </button>
      <button
        type="button"
        onClick={() => setPackagesType(PARCEL_TYPE[1])}
        className={`${
          packagesType === PARCEL_TYPE[1] ? 'bg-white' : 'bg-semi-light-grey'
        } h-10 rounded-t-md border-[1px] border-t-2 border-semi-light-grey px-2 font-semibold`}
      >
        Pedidos no encontrados
      </button>
    </div>
  )

  useEffect(() => {
    if (packagesType === PARCEL_TYPE[0]) {
      setUpdatePackagesFunction(() => updatePackages)
    } else {
      setUpdatePackagesFunction(() => updateMissingPackages)
    }
  }, [packagesType, setUpdatePackagesFunction])

  return (
    <TableSection
      title=""
      fetchData={packagesType === PARCEL_TYPE[0] ? getBilledPackages : getMissingPackages}
      TableHeaders={[
        '',
        'Cliente',
        'Courier',
        'Comuna',
        'Peso declarado',
        'Peso cobrado',
        'Cobrado',
        'Precio base',
        'Transporte'
      ]}
      filters={[]}
      filterOptions={filterOptions}
      renderRow={renderBillRow}
      type="pedidos"
      packagesTypeSelector={packagesTypeSelector}
      setShouldFetch={setShouldFetch}
      shouldFetch={shouldFetch}
    />
  )
}
export default ParcelsBillSection
